import React from 'react';
import PropTypes from 'prop-types';
import Layout from '../components/layout';
import ChallengeSelection from '../components/challenge-selection';

import CodingInstructions from '../components/coding-instructions';
import SEO from '../components/seo';

const CodePage = ({ location }) => (
  <>
    <Layout>
      <SEO
        title="Play Now | Write Code at Code Championship"
        description="Code Championship is a competitive computer coding platform. Learn how to get started writing code!"
        keywords={['build', 'bot', 'create', 'start']}
      />
      <h1>Play Now</h1>
      <div className="stripe-hr thin" />
      <CodingInstructions />
      <ChallengeSelection location={location} />
    </Layout>
  </>
);

CodePage.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string.isRequired,
  }).isRequired,
};

export default CodePage;
