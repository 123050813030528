import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import Create from './create';
import ChallengeList from './challenge-list';

const ChallengeSelection = ({ location }) => {
  const [challengeId, setChallengeId] = useState('');
  const [currentChallenge, setCurrentChallenge] = useState(null);
  const [challengeList, setChallengeList] = useState([]);

  // retrieve all of the challenges on page load
  useEffect(() => {
    axios({
      method: 'GET',
      // use the url to check if we should return experimental challenges
      params: { showPrivateChallenges: location.search.includes('experimental=1') },
      url: `${process.env.ABAMATH_API_URL}/code-championship/projects/challenges`,
    }).then((response) => {
      setChallengeList(response.data);
    });
  }, []);

  // set current challenge based on the selected id
  // if the challenge Id is cleared, leave the old one
  // to allow for a smooth fade out (instead of disappearing text)
  useEffect(() => {
    if (challengeId) {
      setCurrentChallenge(challengeList
        .filter((challenge) => challengeId === challenge.id)[0]);
    }
  }, [challengeId]);

  const difficultyLevels = Object.keys(challengeList
    .reduce((aggregate, challenge) => ({
      ...aggregate,
      [Math.floor(challenge.difficulty)]: true,
    }), {}))
    .map(Number).sort().map(String);

  return (
    <section style={{ position: 'relative', minHeight: '1000px' }}>
      {/* challenge container is full width before a challenge is selected
      then goes to 200px to give column on the side */}
      <section className={`challenge-container${challengeId && ' challenge-selected'}`}>
        {/* Each difficulty gets its own Challenge List */}
        {difficultyLevels.map((level) => (
          <ChallengeList
            key={level}
            challenges={challengeList}
            difficulty={level}
            challengeId={challengeId}
            setChallengeId={setChallengeId}
          />
        ))}
      </section>
      {/* section style gives "slide in" animation effect */}
      <section className={`challenge-display${challengeId && ' challenge-selected'}`}>
        <button
          type="button"
          className="stripe-button secondary"
          onClick={() => setChallengeId('')}
        >
          ← Go Back!
        </button>
        {currentChallenge && <Create currentChallenge={currentChallenge} /> }
      </section>
    </section>
  );
};

ChallengeSelection.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string.isRequired,
  }).isRequired,
};

export default ChallengeSelection;
