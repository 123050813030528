import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import NavigationButton from './navigation-button';
import VideoSection from './video-section';

const CodingInstructions = ({ showButton }) => (
  <div className="two-wide-container">
    <div style={{ marginRight: '20px' }}>
      <VideoSection
        src="https://www.youtube.com/embed/f9qssoSKsCI?rel=0"
        title="Code Championship Platform Introduction"
      />
    </div>
    <div>
      <p>
        Code Championship is a competitive computer coding platform.
        Check out this video to get an idea of how to start!
      </p>
      <p>
        When you are ready to play, select one of the challenges below!
        We recommend starting with Rabbit Race!
      </p>
      <p>
        If you are interested in joining a competitive computer coding tournament,
        check out our
        {' '}
        <Link to="/tournament/">Tournaments</Link>
        {' '}
        page!
      </p>

      {showButton && (
        <NavigationButton
          path="/code/"
          text="Play Now"
        />
      )}
    </div>
  </div>
);

CodingInstructions.propTypes = {
  showButton: PropTypes.bool,
};

CodingInstructions.defaultProps = {
  showButton: false,
};

export default CodingInstructions;
